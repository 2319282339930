/* eslint-disable jsx-a11y/anchor-is-valid */
import UIkit from "uikit";
import React, { useEffect } from "react";

import Layout from "../../components/layout";

import "../../static/scss/policies.scss";

function TermsOfUse() {
    useEffect(() => {
        UIkit.accordion(".uk-accordion", { multiple: true });

        const elms = document.querySelectorAll(".uk-hidden");

        elms.forEach(elm => elm.classList.remove("uk-hidden"));
    }, []);

    return (
        <Layout>
            <div className="uk-section">
                <div className="uk-container uk-container-medium uk-margin-large policies">
                    <h1 className="uk-margin-top">Terms of Use</h1>

                    <p>
                        For the use of Plato Media / Hopster apps, website and services - Effective from December 2013
                    </p>
                    <p>
                        This agreement applies as between you, the User of this Website and Plato Media Ltd, the
                        owner(s) of this Website. Your agreement to comply with and be bound by these Terms and
                        Conditions is deemed to occur upon your first use of the Website. If you do not agree to be
                        bound by these Terms and Conditions, you should stop using the Website immediately.
                    </p>
                    <p>
                        No part of this Website is intended to constitute a contractual offer capable of acceptance.
                        Your order constitutes a contractual offer and our acceptance of that offer is deemed to occur
                        upon our sending a dispatch email to you indicating that your order has been fulfilled and is on
                        its way to you.
                    </p>

                    <ul className="uk-accordion">
                        <li>
                            <a className="uk-accordion-title" href="#">
                                1. Definitions and Interpretation
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>In this Agreement the following terms shall have the following meanings:</p>
                                <p>
                                    “Account” – means collectively the personal information, Payment Information and
                                    credentials used by Users to access Paid Content and / or any communications System
                                    on the Website;
                                </p>
                                <p>
                                    “Content” – means any text, graphics, images, audio, video, software, data
                                    compilations and any other form of information capable of being stored in a computer
                                    that appears on or forms part of this Website;
                                </p>
                                <p>
                                    “Plato” – means Plato Media Ltd, trading as Hopster, of registered business address
                                    1 Vincent Square, London, SW1P 2PN;
                                </p>
                                <p>
                                    “Service” – means collectively any online facilities, tools, services or information
                                    that Plato makes available through the Website either now or in the future,
                                    including but not limited to mobile applications that utilise Website functionality;
                                </p>
                                <p>
                                    “Payment Information” – means any details required for the purchase of Services from
                                    this Website. This includes, but is not limited to, credit / debit card numbers,
                                    bank account numbers and sort codes;
                                </p>
                                <p>
                                    “Purchase Information” – means collectively any orders, invoices, receipts or
                                    similar that may be in hard copy or electronic form;
                                </p>
                                <p>“Premises” – means our registered offices at 1 Vincent Square, London, SW1P 2PN;</p>
                                <p>
                                    “System” – means any online communications infrastructure that Plato makes available
                                    through the Website either now or in the future. This includes, but is not limited
                                    to, web-based email, message boards, live chat facilities and email links;
                                </p>
                                <p>
                                    “User / Users” – means any third party that accesses the Website and is not employed
                                    by Plato and acting in the course of their employment; and
                                </p>
                                <p>
                                    “Website” – this site (e.g. subdomain.hopster.tv) unless expressly excluded by their
                                    own terms of use.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                2. Intellectual Property
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    2.1 Subject to the exceptions in Clause 3 of these Terms of Use, all Content
                                    included on the Website and Services, unless uploaded by Users, including, but not
                                    limited to, text, graphics, logos, icons, images, sound clips, video clips, data
                                    compilations, page layout, underlying code and software is the property of Plato,
                                    our affiliates or other relevant third parties. By continuing to use the Website and
                                    Services you acknowledge that such material is protected by applicable United
                                    Kingdom and International intellectual property and other laws.
                                </p>
                                <p>
                                    2.2 Subject to Clause 4 you may not reproduce, copy, distribute, store or in any
                                    other fashion re-use material from the Website unless otherwise indicated on the
                                    Website or unless given express written permission to do so by Plato.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                3. Third Party Intellectual Property
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    3.1 Unless otherwise expressly indicated, all Intellectual Property rights
                                    including, but not limited to, Copyright and Trademarks, in product images and
                                    descriptions belong to the manufacturers or distributors of such products as may be
                                    applicable.
                                </p>
                                <p>
                                    3.2 Subject to Clause 5 you may not reproduce, copy, distribute, store or in any
                                    other fashion re-use such material unless otherwise indicated on the Website or
                                    unless given express written permission to do so by the relevant manufacturer or
                                    supplier.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                4. Fair Use of Intellectual Property
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    Material from the Website may be re-used without written permission where any of the
                                    exceptions detailed in Chapter III of the Copyright Designs and Patents Act 1988
                                    apply.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                5. Links to Other Websites
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    This Website may contain links to other sites. Unless expressly stated, these sites
                                    are not under the control of Plato or that of our affiliates. We assume no
                                    responsibility for the content of such Websites and disclaim liability for any and
                                    all forms of loss or damage arising out of the use of them. The inclusion of a link
                                    to another site on this Website does not imply any endorsement of the sites
                                    themselves or of those in control of them.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                6. Links to this Website
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    Those wishing to place a link to this Website on other sites may do so only to the
                                    home page of the site
                                    <a href="http://www.hopster.tv"> hopster.tv</a> without prior permission. Deep
                                    linking (i.e. links to specific pages within the site) requires the express
                                    permission of Plato. To find out more please contact us by email at
                                    <a href="mailto:hello@hopster.tv"> hello@hopster.tv</a> or by post to Plato Media
                                    Ltd, 1 Vincent Square, London SW1P 2PN.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                7. Use of Communications Facilities
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    7.1 When using the enquiry form or any other System on the Website you should do so
                                    in accordance with the following rules:
                                </p>
                                <p>7.1.1 You must not use obscene or vulgar language;</p>
                                <p>
                                    7.1.2 You must not submit Content that is unlawful or otherwise objectionable. This
                                    includes, but is not limited to, Content that is abusive, threatening, harassing,
                                    defamatory, ageist, sexist or racist;
                                </p>
                                <p>7.1.3 You must not submit Content that is intended to promote or incite violence;</p>
                                <p>
                                    7.1.4 It is advised that submissions are made using the English language as we may
                                    be unable to respond to enquiries submitted in any other languages;
                                </p>
                                <p>
                                    7.1.5 The means by which you identify yourself must not violate these terms of use
                                    or any applicable laws;
                                </p>
                                <p>
                                    7.1.6 You must not impersonate other people, particularly employees and
                                    representatives of Plato or our affiliates; and
                                </p>
                                <p>
                                    7.1.7 You must not use our System for unauthorised mass-communication such as “spam”
                                    or “junk mail”.
                                </p>
                                <p>
                                    7.2 You acknowledge that Plato reserves the right to monitor any and all
                                    communications made to us or using our System.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                8. Accounts
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    8.1 In order to procure Services on this Website and to use the forum facilities you
                                    are required to create an Account which will contain certain personal details and
                                    Payment Information which may vary based upon your use of the Website as we may not
                                    require payment information until you wish to make a purchase. By continuing to use
                                    this Website you represent and warrant that:
                                </p>
                                <p>8.1.1 all information you submit is accurate and truthful;</p>
                                <p>
                                    8.1.2 you have permission to submit Payment Information where permission may be
                                    required; and
                                </p>
                                <p>8.1.3 you will keep this information accurate and up-to-date.</p>
                                <p>
                                    Your creation of an Account is further affirmation of your representation and
                                    warranty.
                                </p>
                                <p>
                                    8.2 It is recommended that you do not share your Account details, particularly your
                                    username and password. Plato accepts no liability for any losses or damages incurred
                                    as a result of your Account details being shared by you. If you use a shared
                                    computer, it is recommended that you do not save your Account details in your
                                    internet browser.
                                </p>
                                <p>
                                    8.3 If you have reason to believe that your Account details have been obtained by
                                    another without consent, you should contact Plato immediately to suspend your
                                    Account and cancel any unauthorised orders or payments that may be pending. Please
                                    be aware that orders or payments can only be cancelled up until provision of
                                    Services has commenced. In the event that an unauthorised provision commences prior
                                    to your notifying us of the unauthorised nature of the order or payment, Plato will
                                    suspend provision of Services and the withdrawal of any scheduled payments pending
                                    investigation. Following investigation, it shall be determined whether or not to
                                    cancel the Services and make a full or partial refund of the payment.
                                </p>
                                <p>
                                    8.4 When choosing your username you are required to adhere to the terms set out
                                    above in Clause 7. Any failure to do so could result in the suspension and/or
                                    deletion of your Account.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                9. Termination and Cancellation
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    9.1 Either Plato or you may terminate your Account. If Plato terminates your
                                    Account, you will be notified by email and an explanation for the termination will
                                    be provided. Notwithstanding the foregoing, we reserve the right to terminate
                                    without giving reasons.
                                </p>
                                <p>
                                    9.2 If Plato terminates your Account, any current or pending orders or payments on
                                    your Account will be cancelled and provision of Services will not commence.
                                </p>
                                <p>
                                    9.3 Plato reserves the right to cancel orders or payments without stating reasons,
                                    for any reason prior to processing payment or commencing Services provision.
                                </p>
                                <p>
                                    9.4 If orders or payments are cancelled for any reason prior to commencement of
                                    Services provision you will be refunded any monies paid in relation to those
                                    purchases.
                                </p>
                                <p>
                                    9.5 If you terminate your Account any non-completed orders or payments will be
                                    cancelled and you will be refunded any monies paid in relation to those orders.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                10. Payment
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    10.1 Any and all monies are due for payment on completion of the order or on the
                                    dates, or intervals specified in that order as may be appropriate, unless
                                    alternative arrangements are agreed between the Purchaser and Plato.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                11. Services, Pricing and Availability
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    11.1 Whilst every effort has been made to ensure that all descriptions of Services
                                    available from Plato correspond to the actual Services, Plato is not responsible for
                                    any variations from these descriptions. This does not exclude our liability for
                                    mistakes due to negligence on our part and refers only to variations of the correct
                                    Services, not different Services altogether. Please refer to sub-Clause 12.4 for
                                    incorrect Services.
                                </p>
                                <p>11.2 Where appropriate, you may be required to select the required Services.</p>
                                <p>
                                    11.3 All pricing information on the Website is correct at the time of going online.
                                    Plato reserves the right to change prices and alter or remove any special offers
                                    from time to time and as necessary. All pricing information is reviewed and updated
                                    every calendar month.
                                </p>
                                <p>
                                    11.4 In the event that prices are changed during the period between an order being
                                    placed for Services and Plato processing that order and taking payment, provision of
                                    Services shall commence as per your order and you will be charged the original
                                    price.
                                </p>
                                <p>
                                    11.5 Hopster Coding Safari is a separate app created and owned by Hopster. Current
                                    Hopster subscribers can unlock additional content within the Coding Safari app by
                                    using their Hopster account details within the Hopster Coding Safari app. Customers
                                    without a Hopster subscription may choose to purchase the additional content by
                                    means of a one-off payment.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                12. Provision of Services
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    12.1 Provision of Services shall commence when full payment has been received or as
                                    otherwise detailed in the Terms & Conditions pertaining directly to those Services.
                                </p>
                                <p>
                                    12.2 Plato shall use its best endeavours to provide the Services with reasonable
                                    skill and care.
                                </p>
                                <p>
                                    12.3 Provision of all Services shall be subject to the terms of use pertaining
                                    directly to those Services.
                                </p>
                                <p>
                                    12.4 In the event that Services are provided that are not in conformity with your
                                    order and thus incorrect, you should contact us within 10 days to inform us of the
                                    mistake. Plato will ensure that any necessary corrections to the Services provided
                                    are made within 7 working days.
                                </p>
                                <p>
                                    12.5 Plato reserves the right to exercise discretion with respect to any alterations
                                    to Services under the provisions of this Clause 12. Factors which may be taken into
                                    account in the exercise of this discretion include, but are not limited to:
                                </p>
                                <p> 12.5.1 Any use or enjoyment that you may have already derived from the Services;</p>
                                <p>
                                    12.5.2 Any characteristics of the Services which may mean that cessation of
                                    provision is impossible without significant further work on the part and at the
                                    expense of Plato.
                                </p>
                                <p> Such discretion to be exercised only within the confines of the law.</p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                13. Privacy
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    Use of the Website is also governed by our Privacy Policy which is incorporated into
                                    these terms of use by this reference. To view the Privacy Policy, please click on
                                    the link above.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                14. Disclaimers
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    14.1 Plato makes no warranty or representation that the Website will meet your
                                    requirements, that it will be of satisfactory quality, that it will be fit for a
                                    particular purpose, that it will not infringe the rights of third parties, that it
                                    will be compatible with all systems, that it will be secure and that all information
                                    provided will be accurate. We make no guarantee of any specific results from the use
                                    of our Services.
                                </p>
                                <p>
                                    14.2 No part of this Website is intended to constitute advice and the Content of
                                    this Website should not be relied upon when making any decisions or taking any
                                    action of any kind.
                                </p>
                                <p>
                                    14.3 No part of this Website is intended to constitute a contractual offer capable
                                    of acceptance.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                15. Changes to the Services and these Terms of Use
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    Plato reserves the right to change the Website, its Content or these Terms of Use at
                                    any time. You will be bound by any changes to the Terms of Use from the first time
                                    you use the Website following the changes. If Plato is required to make any changes
                                    to Terms of Use by law, these changes will apply automatically to any orders
                                    currently pending in addition to any orders placed by you in the future.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                16. Availability of the Website
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    The Service is provided “as is” and on an “as available” basis. We give no warranty
                                    that the Service will be free of defects and / or faults. To the maximum extent
                                    permitted by law we provide no warranties (express or implied) of fitness for a
                                    particular purpose, accuracy of information, compatibility and satisfactory quality.
                                </p>
                                <p>
                                    Plato accepts no liability for any disruption or non-availability of the Website
                                    resulting from external causes including, but not limited to, ISP equipment failure,
                                    host equipment failure, communications network failure, power failure, natural
                                    events, acts of war or legal restrictions and censorship.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                17. Limitation of Liability
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    17.1 To the maximum extent permitted by law, Plato accepts no liability for any
                                    direct or indirect loss or damage, foreseeable or otherwise, including any indirect,
                                    consequential, special or exemplary damages arising from the use of the Website or
                                    any information contained therein. Users should be aware that they use the Website
                                    and its Content at their own risk.
                                </p>
                                <p>
                                    17.2 Nothing in these Terms of Use excludes or restricts Plato’s liability for death
                                    or personal injury resulting from any negligence or fraud on the part of Plato.
                                </p>
                                <p>
                                    17.3 Nothing in these Terms of Use excludes or restricts Plato’s liability for any
                                    direct or indirect loss or damage arising out of the incorrect provision of Services
                                    or out of reliance on incorrect information included on the Website.
                                </p>
                                <p>
                                    17.4 Whilst every effort has been made to ensure that these terms of use adhere
                                    strictly with the relevant provisions of the Unfair Contract Terms Act 1977, in the
                                    event that any of these terms are found to be unlawful, invalid or otherwise
                                    unenforceable, that term is to be deemed severed from these terms of use and shall
                                    not affect the validity and enforceability of the remaining terms of use. This term
                                    shall apply only within jurisdictions where a particular term is illegal.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                18. No Waiver
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    In the event that any party to these Terms of Use fails to exercise any right or
                                    remedy contained herein, this shall not be construed as a waiver of that right or
                                    remedy.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                19. Previous Terms of Use
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    In the event of any conflict between these Terms of Use and any prior versions
                                    thereof, the provisions of these Terms of Use shall prevail unless it is expressly
                                    stated otherwise.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                20. Notices
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    All notices / communications shall be given to us either by post to our Premises
                                    (see address above) or by email to info@hopster.tv. Such notice will be deemed
                                    received 3 days after posting if sent by first class post, the day of sending if the
                                    email is received in full on a business day and on the next business day if the
                                    email is sent on a weekend or public holiday.
                                </p>
                            </div>
                        </li>

                        <li>
                            <a className="uk-accordion-title" href="#">
                                21. Law and Jurisdiction
                            </a>
                            <div className="uk-accordion-content uk-hidden">
                                <p>
                                    These terms of use and the relationship between you and Plato shall be governed by
                                    and construed in accordance with the Law of England and Wales and Plato and you
                                    agree to submit to the exclusive jurisdiction of the Courts of England and Wales.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </Layout>
    );
}

export default TermsOfUse;
